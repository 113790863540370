
















import { tasksStore } from '@/store'
import { Vue, Component, Prop } from 'vue-property-decorator'

interface BtnToggleOption {
  value: number | null;
  label: string;
}

@Component({
  name: 'SelectorBtnToggle',
})
export default class SelectorBtnToggle extends Vue {
  @Prop({ type: Number, default: null }) readonly!: number

  get btnToggleOptions (): BtnToggleOption[] {
    const options = tasksStore.getters.importanceOptions.map(
      (importance): BtnToggleOption => ({
        value: importance,
        label: `${importance}`,
      }),
    )
    options.unshift({ value: null, label: this.$t('common.no').toString() })
    return options
  }
}
